import {mdiArrowLeft, mdiChevronLeft, mdiChevronRight} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import CameraField from 'Components/CameraField';
import CheckboxGroupField from 'Components/Forms/CheckboxGroupField';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import SelectField from 'Components/Forms/SelectField';
import SwitchField from 'Components/Forms/SwitchField';
import TextareaField from 'Components/Forms/TextareaField';
import TextField from 'Components/Forms/TextField';
import SyncSpinner from 'Components/SyncSpinner';
import {ErrorMessage, FieldArray, Form, Formik} from 'formik';
import AttendeesList from 'Pages/Jobs/AttendeesList';
import EquipmentList from 'Pages/Jobs/EquipmentList';
import HazardList from 'Pages/Jobs/HazardList';
import PPEList from 'Pages/Jobs/PPEList';
import StepList from 'Pages/Jobs/StepList';
import TrainingList from 'Pages/Jobs/TrainingList';
import React, {useContext, useEffect, useState} from 'react';
import {geocodeByLatLng} from 'react-google-places-autocomplete';
import {useHistory, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import {JobContext} from 'Support/Contexts/JobContext';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import useOrganization from 'Support/hooks/useOrganization';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import {v4 as uuidv4} from 'uuid';
import * as Yup from 'yup';


const BasicDetailsStep = ({currentJob, id, jobs, newTA, setNewTa, setPage, templates, updateCurrentJobId, loading}) => {
  const formikProps = {
    initialValues: {
      job_id: currentJob?.id || '',
      template_id: null,
      attendees: [],
      activity: '',
      location: '',
      training: [],
      emergency_location: '',
      hazards: [],
      ppe: [],
      equipment: [],
      steps: [],
    },
  };

  const validationSchema = Yup.object().shape({
    job_id: Yup.string()
      .required('Please select a job'),

    attendees: Yup.array()
      .test('attendees', 'Please enter at least one attendee', items => items.filter(Boolean).length),

    activity: Yup.string()
      .required('Please provide an activity').max(500, 'Activity must be at most 500 characters'),

    location: Yup.string()
      .required('Please provide a location'),

    emergency_location: Yup.string()
      .required('Please provide an emergency location'),

    steps: Yup.array()
      .test('steps', 'Please enter at least one step', items => items.some(item => item.description?.length)),
  });

  const onSubmit = useFormikSubmit(async values => {
    setNewTa({
      ...newTA,
      ...values,
    });
    setPage(2);
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({values, setFieldValue, isValid}) => {
        useEffect(() => {
          if (!values.job_id && id) {
            setFieldValue('job_id', parseInt(id));
          }
        }, [values.job_id, id]);

        useEffect(() => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async ({coords: position}) => {
              if (!position.latitude || !position.longitude || typeof geocodeByLatLng !== 'function') return;
              const coordinates = `${position.latitude},${position.longitude}`;

              geocodeByLatLng({lat: position.latitude, lng: position.longitude})
                .then(results => {
                  setFieldValue('location', results[0]?.formatted_address || coordinates);
                }).catch(() => setFieldValue('location', coordinates));
            });
          }
        }, []);

        useEffect(() => {
          updateCurrentJobId(values.job_id);
        }, [values.job_id]);

        const populate = id => {
          const values = templates?.find(template => template.id === id);

          if (values) {
            setFieldValue('activity', values.activity || '');
            setFieldValue('training', values.training || '');
            setFieldValue('hazards', values.hazards || []);
            setFieldValue('attendees', values.attendees || []);
            setFieldValue('ppe', values.ppe || []);
            setFieldValue('equipment', values.equipment || []);
            setFieldValue('steps', values.steps || []);
          }
        };

        return (
          <Form className="bg-white shadow-md rounded-lg -m-4 p-4">
            <FormWrapper>
              {!id && (
                <FieldWrapper>
                  <SelectField
                    name="job_id" label="Select a Job"
                    options={jobs} valueKey="id" labelKey="displayName"
                  />
                </FieldWrapper>
              )}

              <FieldWrapper>
                <SelectField
                  name="template_id" label="Task Templates (optional)"
                  options={templates} valueKey="id" labelKey="activity" onChange={populate}
                />
              </FieldWrapper>

              <FieldWrapper>
                <FieldArray name="attendees">
                  {({push, ...props}) => <AttendeesList attendees={values.attendees || []} push={push} {...props}/>}
                </FieldArray>
                <ErrorMessage name="attendees" component="div" className="text-red-500 text-xs italic font-content"/>
              </FieldWrapper>

              <FieldWrapper>
                <TextField name="activity" label="What are you doing?"/>
              </FieldWrapper>

              <FieldWrapper>
                <FieldArray name="steps">
                  {({push, ...props}) => <StepList steps={values.steps || []} push={push} {...props}/>}
                </FieldArray>
                <ErrorMessage name="steps" component="div" className="text-red-500 text-xs italic font-content"/>
              </FieldWrapper>

              <FieldWrapper>
                <TextField name="location"/>
              </FieldWrapper>

              <FieldWrapper>
                <TextField name="emergency_location"/>
              </FieldWrapper>

              <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                <div/>
                <PrimaryButton type="submit" className="w-full" >
                  Next <Icon path={mdiChevronRight} size={1}/>
                </PrimaryButton>
              </FieldWrapper>
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

const TrainingAndEquipmentStep = ({newTA, setNewTa, setPage}) => {
  const formikProps = {
    initialValues: {
      equipment: newTA.equipment || [],
      ppe: newTA.ppe || [],
      training: newTA.training || [],
    },
  };

  const validationSchema = Yup.object().shape({
    training: Yup.array()
      .test('training', 'Please enter at least one training item', items => items.some(item => item.description?.length)),

    ppe: Yup.array()
      .test('ppe', 'Please enter at least one PPE item', items => items.some(item => item.description?.length)),

    equipment: Yup.array()
      .test('equipment', 'Please enter at least one equipment item', items => items.some(item => item.description?.length)),
  });

  const onSubmit = useFormikSubmit(async values => {
    setNewTa({
      ...newTA,
      ...values,
    });
    setPage(3);
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
      {({values, setFieldValue, isValid}) => (
        <Form className="bg-white shadow-md rounded-lg -m-4 p-4">
          <FormWrapper>
            <FieldWrapper>
              <FieldArray name="training">
                {({push, ...props}) => <TrainingList training={values.training || []} push={push} {...props}/>}
              </FieldArray>
              <ErrorMessage name="training" component="div" className="text-red-500 text-xs italic font-content"/>
            </FieldWrapper>

            <FieldWrapper>
              <FieldArray name="ppe">
                {({push, ...props}) => <PPEList ppe={values.ppe || []} push={push} {...props}/>}
              </FieldArray>
              <ErrorMessage name="ppe" component="div" className="text-red-500 text-xs italic font-content"/>
            </FieldWrapper>

            <FieldWrapper>
              <FieldArray name="equipment">
                {({push, ...props}) => (
                  <EquipmentList equipment={values.equipment || []} push={push} {...props}/>
                )}
              </FieldArray>
              <ErrorMessage name="equipment" component="div" className="text-red-500 text-xs italic font-content"/>
            </FieldWrapper>

            <div className="mt-6 grid grid-cols-2 gap-4">
              <SecondaryButton as="div" className="w-full" onClick={() => setPage(1)}>
                <Icon path={mdiChevronLeft} size={1}/> Prev
              </SecondaryButton>
              <PrimaryButton type="submit" className="w-full" disabled={!isValid}>
                Next <Icon path={mdiChevronRight} size={1}/>
              </PrimaryButton>
            </div>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

const HazardsStep = ({newTA, setNewTa, setPage}) => {
  const formikProps = {
    initialValues: {
      hazards: newTA.hazards || [],
    },
  };

  const validationSchema = Yup.object().shape({

    hazards: Yup.array()
      .test('hazards', 'Please enter at least one Hazard', items => items.some(item => item.description?.length))
      .of(Yup.object()
        .test('hazards', 'Please enter Initial risk rating', value => {
          if (value?.description !== undefined) {
            return value.risk_rating;
          }
          return true;
        })
        .test('hazards', 'Please enter Residual risk rating', value => {
          if (value?.description !== undefined) {
            return value.residual_risk_rating !== undefined;
          }
          return true;
        }),
      ),
  });

  const onSubmit = useFormikSubmit(async values => {
    setNewTa({
      ...newTA,
      ...values,
    });
    setPage(4);
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
      {({values, setFieldValue, isValid}) => (
        <Form className="bg-white shadow-md rounded-lg -m-4 p-4">
          <FormWrapper>
            <FieldWrapper>
              <FieldArray name="hazards">
                {({push}) => <HazardList hazards={values.hazards || []} push={push} setFieldValue={setFieldValue}/>}
              </FieldArray>
              {values.hazards.filter(hazard => hazard.description.length > 0).length === 0 &&
                <ErrorMessage name="hazards" component="div" className="text-red-500 text-xs italic font-content"/>
              }
            </FieldWrapper>

            <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
              <SecondaryButton as="div" className="w-full" onClick={() => setPage(2)}>
                <Icon path={mdiChevronLeft} size={1}/> Prev
              </SecondaryButton>
              <PrimaryButton type="submit" className="w-full" disabled={!isValid}>
                Next <Icon path={mdiChevronRight} size={1}/>
              </PrimaryButton>
            </FieldWrapper>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

const ChecksAndCommentsStep = ({companyChecks, newTA, setNewTa, setPage}) => {
  const formikProps = {
    initialValues: {
      checks: [],
      site_good: false,
      additional_comments: '',
    },
  };

  const validationSchema = Yup.object().shape({
    checks: Yup.array()
      .test('checks', 'Please perform all the checks', items => items?.length === companyChecks?.length),
    site_good: Yup.bool().oneOf([true], 'Field must be checked'),
    additional_comments: Yup.string(),
  });

  const onSubmit = useFormikSubmit(async values => {
    setNewTa({
      ...newTA,
      ...values,
    });
    setPage(5);
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({values, setFieldValue, isValid}) => (
        <Form className="bg-white shadow-md rounded-lg -m-4 p-4">
          <FormWrapper>
            <FieldWrapper>
              <CheckboxGroupField
                name="checks"
                options={companyChecks}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.caption}
              />
            </FieldWrapper>

            <FieldWrapper>
              <SwitchField name="site_good" label="Site status" yesText="Good to go" noText="Not good to go"/>
            </FieldWrapper>

            <FieldWrapper>
              <TextareaField name="additional_comments" label="Additional Comments/Toolbox Talk" className="h-20 w-full"
                             hint="(if an action is raised please create and assign a task)"/>
            </FieldWrapper>

            <div className="mt-6 grid grid-cols-2 gap-4">
              <SecondaryButton as="div" className="w-full" onClick={() => setPage(3)}>
                <Icon path={mdiChevronLeft} size={1}/> Prev
              </SecondaryButton>
              <PrimaryButton type="submit" className="w-full" disabled={!isValid}>
                Next <Icon path={mdiChevronRight} size={1}/>
              </PrimaryButton>
            </div>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

const AttendeesPhotoStep = ({newTA, setPage, setSubmitting, setTaskAnalysis, taskAnalysis}) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const history = useHistory();
  const {organization_id} = useOrganization();

  const formikProps = {
    initialValues: {},
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = useFormikSubmit(async values => {
    setSubmitting(true);
    const updatedTaskAnalysis = [...taskAnalysis, {
      ...newTA,
      temp_id: uuidv4(),
      organization_id: organization_id,
      files: uploadedImages || [],
    }];

    setTaskAnalysis(updatedTaskAnalysis);

    await valueStore.set(`taskAnalysis`, updatedTaskAnalysis);

    history.push(route('job', {id: newTA.job_id}));
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({values, setFieldValue, isValid}) => (
        <Form className="bg-white shadow-md rounded-lg -m-4 p-4">
          <FormWrapper>
            <p className="text-sm bg-blue-100 text-blue-700 p-4 rounded mb-2">Please take a photo of all attendees to confirm and acknowledge
              attendance.</p>
            <CameraField uploadedImages={uploadedImages} setUploadedImages={setUploadedImages}/>

            <div className="mt-6 grid grid-cols-2 gap-4">
              <SecondaryButton as="div" className="w-full" onClick={() => setPage(4)}>
                <Icon path={mdiChevronLeft} size={1}/> Prev
              </SecondaryButton>
              <PrimaryButton type="submit" className="w-full" disabled={!isValid}>
                Submit <Icon path={mdiChevronRight} size={1}/>
              </PrimaryButton>
            </div>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

const AddTaskAnalysis = () => {
  const {id} = useParams();
  const {currentJob, updateCurrentJobId} = useContext(JobContext);
  const isOnline = useOnlineStatus();

  const [companyChecks, setCompanyChecks] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [taskAnalysis, setTaskAnalysis] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [newTA, setNewTa] = useState({
    created_at: 'now',
    equipment: [],
    hazards: [],
    ppe: [],
    steps: [],
    files: [],
    new: true,
  });

  const sync = async () => {
    if (!syncing) {
      setSyncing(true);

      setCompanyChecks(await api.syncCompanyChecks());
      setJobs(await api.syncJobs().then(response => {
        return response.map(job => ({
          ...job,
          displayName: `${job.name}, ${job.location || ''}`,
        }));
      }));
      setTemplates(await api.syncTaskAnalysisTemplates());

      setSyncing(false);
    }
  };

  useEffect(async () => {
    if (id) {
      updateCurrentJobId(id);
    }
    setCompanyChecks(await valueStore.getArray(`companyChecks`) || []);
    setJobs(await valueStore.getArray(`jobs`).then(response => {
      return response.map(job => ({
        ...job,
        displayName: `${job.name}, ${job.location || ''}`,
      }));
    }) || []);
    setTaskAnalysis(await valueStore.getArray(`taskAnalysis`) || []);
    setTemplates(await valueStore.getArray(`taskAnalysisTemplates`) || []);
  }, [id]);

  usePageTitle(currentJob ? `Task Analysis - ${currentJob?.name}` : `Create Task Analysis`);

  useEffect(async () => {
    if (isOnline) {
      await sync();
    }
  }, [JSON.stringify(taskAnalysis)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={syncing || submitting}/>
      {id && (
        <SecondaryButton as={Link} to={route('job', {id: id})} className="w-full mb-12  py-2">
          <Icon path={mdiArrowLeft} size={1} className="mr-1"/> Cancel
        </SecondaryButton>
      )}

      <div className={cls(page !== 1 && 'hidden')}>
        <BasicDetailsStep
          currentJob={currentJob}
          id={id}
          jobs={jobs}
          newTA={newTA}
          setNewTa={setNewTa}
          setPage={setPage}
          templates={templates}
          updateCurrentJobId={updateCurrentJobId}
          loading={syncing || submitting}
        />
      </div>

      <div className={cls(page !== 2 && 'hidden')}>
        <TrainingAndEquipmentStep
          newTA={newTA}
          setNewTa={setNewTa}
          setPage={setPage}
        />
      </div>

      <div className={cls(page !== 3 && 'hidden')}>
        <HazardsStep
          newTA={newTA}
          setNewTa={setNewTa}
          setPage={setPage}
        />
      </div>

      <div className={cls(page !== 4 && 'hidden')}>
        <ChecksAndCommentsStep
          companyChecks={companyChecks}
          newTA={newTA}
          setNewTa={setNewTa}
          setPage={setPage}
        />
      </div>

      <div className={cls(page !== 5 && 'hidden')}>
        <AttendeesPhotoStep
          newTA={newTA}
          setPage={setPage}
          setSubmitting={setSubmitting}
          setTaskAnalysis={setTaskAnalysis}
          taskAnalysis={taskAnalysis}
        />
      </div>
    </div>
  );
};

export default AddTaskAnalysis;
